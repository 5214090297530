import * as XLSX from 'xlsx/xlsx.mjs'
import { saveAs } from 'file-saver'

const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length) // convert s to arrayBuffer
  var view = new Uint8Array(buf) // create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF // convert to octet
  return buf
}

class XlsController {
  downloadXls (data = {}) {
    if (data?.items?.length && data?.headers?.[0].length) {
      const worksheet = XLSX.utils.json_to_sheet(data.items)
      const workbook = XLSX.utils.book_new()

      workbook.Props = {
        Title: data?.fileName ? data.fileName : 'Nuevo documento',
        Subject: data?.fileSubject ? data.fileSubject : '',
        Author: 'C.M.C.B.A.',
        CreatedDate: new Date(),
      }

      if (data.widths?.length) {
        worksheet['!cols'] = data.widths
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, data?.sheetName ? data.sheetName : 'Nueva hoja')

      XLSX.utils.sheet_add_aoa(worksheet, data.headers, { origin: 'A1' })

      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

      saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'test.xlsx')
      return true
    } else {
      console.log('Define : items and headers')
      return false
    }
  }
}

export default new XlsController()
